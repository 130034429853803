class ConstDavosMenu extends HTMLUListElement {
    connectedCallback() {
      let current = this.getAttribute("current");
      if (current) {
        let currentElem = this.querySelector("." + current);
        if (currentElem) {
          currentElem.classList.add("current");
        }
      }
    }
};

customElements.define("const-davos-menu", ConstDavosMenu, { extends: 'ul' });
